import React from "react"
import { graphql } from "gatsby"
import MDXRenderer from "./elements/MyMdx"
import Layout from "./Layout"
import RenderImage from "./elements/RenderImage"
import DisplayTags from "./elements/TagList"
import styles from "./postlayout.module.css"
import * as helpers from "./helpers"
import { Disqus } from "gatsby-plugin-disqus"
import { useImageSharp } from "./Image"
import * as moment from "moment"
import { GatsbySeo, BlogPostJsonLd } from "gatsby-plugin-next-seo"

const StickyFooter = ({ children }) => {
  return (
    <div className="fixed text-center inset-x-0 bottom-0 bg-gray-200 z-9 border-t-2 border-gray-100 p-3">
      {children}
    </div>
  )
}

const ButtonIcon = ({ iconType }) => {
  if (iconType === "Download") {
    return (
      <svg
        className="fill-current w-4 h-4 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
      </svg>
    )
  }

  return null
}

const Button = ({ icon, cta, href }) => {
  return (
    <a
      href={href}
      target="_blank"
      className="bg-yellow-500 hover:bg-gray-400 text-gray-800 font-bold p300y-2 px-4 py-2 rounded inline-flex items-center m-1"
    >
      <ButtonIcon iconType={icon} />
      <span>{cta}</span>
    </a>
  )
}

const PostFooter = ({ footerData }) => {
  if (footerData === undefined || footerData.length === 0) {
    return null
  }

  const buttons = footerData.map(ele => {
    return <Button icon={ele.buttonIcon} href={ele.linkTo} cta={ele.cta} />
  })

  return <StickyFooter>{buttons}</StickyFooter>
}

export default function PostLayout({ data: { mdx, site } }) {
  let post = mdx
  const postImageNodes = useImageSharp(post.frontmatter.image)
  const t0 = moment()
  const publishDate = post.timestamp || t0
  const { postUrl } = post
  console.log(postUrl)

  post = helpers.serializePost(post)
  const disqusConfig = {
    url: postUrl,
    identifier: post.id,
    title: post.frontmatter.title,
  }
  var footerData = []

  if (post.frontmatter.stickyFooter) {
    footerData = post.frontmatter.stickyFooter.map(ele => {
      return {
        cta: ele.cta,
        buttonIcon: "Download",
        linkTo: ele.linkTo,
      }
    })
  }

  const fixedImages = []
  if (postImageNodes && postImageNodes.fixed) {
    fixedImages.push({
      width: postImageNodes.fixed.width,
      height: postImageNodes.fixed.height,
      url: `${site.siteMetadata.siteUrl}${postImageNodes.fixed.src}`,
      alt: post.frontmatter.excerpt,
    })
  }

  // TODO: add default image:

  return (
    <Layout>
      <BlogPostJsonLd
        url={postUrl}
        title={post.frontmatter.title}
        images={fixedImages.map(ele => {
          return ele.url
        })}
        datePublished={publishDate.format()}
        dateModified={publishDate.format()}
        authorName={site.siteMetadata.author}
        description={post.frontmatter.excerpt}
      />
      <GatsbySeo
        title={post.frontmatter.title}
        description={post.frontmatter.excerpt}
        canonical={postUrl}
        openGraph={{
          url: postUrl,
          title: post.frontmatter.title,
          description: post.frontmatter.excerpt,
          images: fixedImages,
          site_name: "jsfour.com",
        }}
        twitter={{
          handle: site.siteMetadata.twitter,
          site: site.siteMetadata.twitter,
          cardType: "summary_large_image",
        }}
      />

      <PostFooter footerData={footerData} />
      <div className={styles.post}>
        <RenderImage frontmatter={post.frontmatter} />
        <h1>{post.frontmatter.title}</h1>
        <h3>{post.frontmatter.subtitle}</h3>
        <span className="post-date">
          {post.fields.date} | {post.timeToRead} mins reading time
        </span>
        <MDXRenderer>{post.body}</MDXRenderer>
        <div className="mt-5 py-4">
          <DisplayTags tags={post.frontmatter.tags} />
        </div>
        <Disqus config={disqusConfig} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      timeToRead
      tableOfContents
      fields {
        date
        slug
      }
      frontmatter {
        title
        image
        excerpt
        subtitle
        tags
        stickyFooter {
          cta
          linkTo
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        siteHost
        author
        twitter
      }
    }
  }
`
